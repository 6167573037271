<template>
    <div class="dashboard">
        <MDBContainer class="mt-3 page-container">
            <Pageheader :title="`Dashboard`" />
            <fieldset :disabled="!$store.state.online">
                <MDBTable style="width: 100%;" align="middle" responsive sm striped variant="light" class="mt-3 shadow">
                    <tbody>
                        <tr>
                            <td>Customers</td>
                            <td class="text-end">
                                <MDBDropdown v-model="tools[5]">
                                    <MDBDropdownToggle @click="tools[5] = !tools[5]" size="sm"></MDBDropdownToggle>
                                    <MDBDropdownMenu aria-labelledby="dropdownMenuButton">
                                        <MDBDropdownItem href="#"
                                            @click.prevent="$root.getSingleTable('customers'); $root.downloadModal = true">
                                            <MDBIcon icon="download" iconStyle="fas" /> Download Customers
                                        </MDBDropdownItem>
                                        <MDBDropdownItem :href="`#/customers`">
                                            <MDBIcon icon="pencil-alt" iconStyle="fas" /> View
                                        </MDBDropdownItem>
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            </td>
                        </tr>
                        <tr>
                            <td>Regions</td>
                            <td class="text-end">
                                <MDBDropdown v-model="tools[0]">
                                    <MDBDropdownToggle @click="tools[0] = !tools[0]" size="sm"></MDBDropdownToggle>
                                    <MDBDropdownMenu aria-labelledby="dropdownMenuButton">
                                        <MDBDropdownItem href="#"
                                            @click.prevent="$root.getSingleTable('regions'); $root.downloadModal = true">
                                            <MDBIcon icon="download" iconStyle="fas" /> Download Regions
                                        </MDBDropdownItem>
                                        <MDBDropdownItem :href="`#/regions`"
                                            v-if="$store.state.user.roles.indexOf('administrator') != -1">
                                            <MDBIcon icon="pencil-alt" iconStyle="fas" /> View
                                        </MDBDropdownItem>
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            </td>
                        </tr>
                        <tr>
                            <td>Compressors</td>
                            <td class="text-end">
                                <MDBDropdown v-model="tools[1]">
                                    <MDBDropdownToggle @click="tools[1] = !tools[1]" size="sm"></MDBDropdownToggle>
                                    <MDBDropdownMenu aria-labelledby="dropdownMenuButton">
                                        <MDBDropdownItem href="#"
                                            @click.prevent="$root.getSingleTable('compressors'); $root.downloadModal = true">
                                            <MDBIcon icon="download" iconStyle="fas" /> Download Compressors
                                        </MDBDropdownItem>
                                        <MDBDropdownItem v-if="$store.state.user.roles.indexOf('administrator') != -1"
                                            :href="`#/compressors`">
                                            <MDBIcon icon="pencil-alt" iconStyle="fas" /> View
                                        </MDBDropdownItem>
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            </td>
                        </tr>
                        <tr>
                            <td>Dryers</td>
                            <td class="text-end">
                                <MDBDropdown v-model="tools[2]">
                                    <MDBDropdownToggle @click="tools[2] = !tools[2]" size="sm"></MDBDropdownToggle>
                                    <MDBDropdownMenu aria-labelledby="dropdownMenuButton">
                                        <MDBDropdownItem href="#"
                                            @click.prevent="$root.getSingleTable('dryers'); $root.downloadModal = true">
                                            <MDBIcon icon="download" iconStyle="fas" /> Download Dryers
                                        </MDBDropdownItem>
                                        <MDBDropdownItem v-if="$store.state.user.roles.indexOf('administrator') != -1"
                                            :href="`#/dryers`">
                                            <MDBIcon icon="pencil-alt" iconStyle="fas" /> View
                                        </MDBDropdownItem>
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            </td>
                        </tr>
                        <tr>
                            <td>Filters</td>
                            <td class="text-end">
                                <MDBDropdown v-model="tools[3]">
                                    <MDBDropdownToggle @click="tools[3] = !tools[3]" size="sm"></MDBDropdownToggle>
                                    <MDBDropdownMenu aria-labelledby="dropdownMenuButton">
                                        <MDBDropdownItem href="#"
                                            @click.prevent="$root.getSingleTable('filters'); $root.downloadModal = true">
                                            <MDBIcon icon="download" iconStyle="fas" /> Download Filters
                                        </MDBDropdownItem>
                                        <MDBDropdownItem v-if="$store.state.user.roles.indexOf('administrator') != -1"
                                            :href="`#/filters`">
                                            <MDBIcon icon="pencil-alt" iconStyle="fas" /> View
                                        </MDBDropdownItem>
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            </td>
                        </tr>
                        <tr>
                            <td>Territories</td>
                            <td class="text-end">
                                <MDBDropdown v-model="tools[4]">
                                    <MDBDropdownToggle @click="tools[4] = !tools[4]" size="sm"></MDBDropdownToggle>
                                    <MDBDropdownMenu aria-labelledby="dropdownMenuButton">
                                        <MDBDropdownItem href="#"
                                            @click.prevent="$root.getSingleTable('territories'); $root.downloadModal = true">
                                            <MDBIcon icon="download" iconStyle="fas" /> Download Territories
                                        </MDBDropdownItem>
                                        <MDBDropdownItem v-if="$store.state.user.roles.indexOf('administrator') != -1"
                                            :href="`#/territories`">
                                            <MDBIcon icon="pencil-alt" iconStyle="fas" /> View
                                        </MDBDropdownItem>
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            </td>
                        </tr>
                        <tr>
                            <td>Gallons</td>
                            <td class="text-end">
                                <MDBDropdown v-model="tools[9]">
                                    <MDBDropdownToggle @click="tools[9] = !tools[9]" size="sm"></MDBDropdownToggle>
                                    <MDBDropdownMenu aria-labelledby="dropdownMenuButton">
                                        <MDBDropdownItem href="#"
                                            @click.prevent="$root.getSingleTable('gallons'); $root.downloadModal = true">
                                            <MDBIcon icon="download" iconStyle="fas" /> Download Gallons
                                        </MDBDropdownItem>
                                        <MDBDropdownItem v-if="$store.state.user.roles.indexOf('administrator') != -1"
                                            :href="`#/gallons`">
                                            <MDBIcon icon="pencil-alt" iconStyle="fas" /> View
                                        </MDBDropdownItem>
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            </td>
                        </tr>
                        <tr>
                            <td>Users</td>
                            <td class="text-end">
                                <MDBDropdown v-model="tools[6]">
                                    <MDBDropdownToggle @click="tools[6] = !tools[6]" size="sm"></MDBDropdownToggle>
                                    <MDBDropdownMenu aria-labelledby="dropdownMenuButton">
                                        <MDBDropdownItem href="#"
                                            @click.prevent="$root.getSingleTable('users'); $root.downloadModal = true">
                                            <MDBIcon icon="download" iconStyle="fas" /> Download Users
                                        </MDBDropdownItem>
                                        <MDBDropdownItem v-if="$store.state.user.roles.indexOf('administrator') != -1"
                                            :href="`#/users`">
                                            <MDBIcon icon="pencil-alt" iconStyle="fas" /> View
                                        </MDBDropdownItem>
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            </td>
                        </tr>
                        <tr>
                            <td>Download All Data</td>
                            <td class="text-end">
                                <MDBBtn size="sm" color="primary" href="#"
                                    @click.prevent="$root.download(); $root.downloadModal = true">
                                    <MDBIcon icon="download" iconStyle="fas" />
                                </MDBBtn>
                            </td>
                        </tr>
                    </tbody>
                </MDBTable>
            </fieldset>
            <!-- <div class="text-center mt-3" v-if="$store.state.user.roles.indexOf('administrator') != -1">
                <h6>ENABLE CONSOLE FOR DEBUGGING</h6>
                <input type="checkbox" @change="debugger" id="toggler-debug">
                <label for="toggler-debug"><span></span></label>
            </div> -->
        </MDBContainer>
    </div>
</template>

<script>
// @ is an alias to /src
export default {
    name: 'Dashboard',
    components: {
    },
    data() {
        return {
            vConsole: null,
            tools: [],
            message: '',
            pagination: {
                pages: 0,
                page: 1,
                posts_per_page: 100
            },
            progress: {
                start: 0,
                end: 0,
                progress: 0
            }
        }
    },
    async mounted() {
        for (let i = 0; i < 10; i++) {
            this.tools.push(false);
        }
    },
    methods: {
        debugger(e) {
            if (e.target.checked) {
                this.vConsole = new VConsole({ theme: 'dark' });
            }
            else {
                this.vConsole.destroy();
            }
        }
    }
}
</script>
<style lang="scss">
input[type="checkbox"]#toggler-debug {
    display: none;
}

input[type="checkbox"]#toggler-debug+label[for="toggler-debug"] {
    display: inline-block;
    width: 60px;
    height: 30px;
    background-color: var(--bs-gray-600);
    font-size: 0;
    text-align: left;
    overflow: hidden;
    border-radius: 4px;
}

input[type="checkbox"]#toggler-debug+label>span {
    width: 16px;
    height: 28px;
    display: inline-block;
    background-color: var(--mdb-danger);
    transform: translateX(1px) translateY(1px);
    border-radius: 4px;
    transition: all 0.15s;
    box-shadow: 0 0 4px rgba(#000, 0.6);
}

input[type="checkbox"]#toggler-debug:checked+label>span {
    width: 16px;
    height: 28px;
    display: inline-block;
    background-color: var(--bs-teal);
    transform: translateX(calc(60px - 17px)) translateY(1px);
    border-radius: 4px;
}
</style>